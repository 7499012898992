<template>
    <div class="calendar_main" v-loading="loadingData" >
        <el-form ref="form" :model="filterForm" class="d-flex el-form-item-0">
            <el-form-item>
              <select-filial
                :size="'medium'"
                :placeholder="$t('message.filial')"
                :id="filterForm.filial_id"
                v-model="filterForm.filial_id"
              >
              </select-filial>
            </el-form-item>
        </el-form>

        <FullCalendar ref="fullCalendar" :options="calendarOptions"/>

        <div class="app-modal app-modal__full">
            <el-dialog 
                :visible.sync="taskUpdateDrawer"
                ref="drawerTaskUpdate"
                @opened="drawerOpened('drawerTaskUpdateChild')"
                @closed="drawerClosed('drawerTaskUpdateChild')" 
                :fullscreen="true">
                <div
                    @click="taskUpdateDrawer = false"
                    class="close-modal-stick d-flex align-center p-fixed text-white p-2 font-bold pointer transition"
                >
                    <div class="close-modal-icon text-center rounded-circle transition">
                    <i class="el-icon-close"></i>
                    </div>
                    <span class="close-modal-text text-uppercase ml-2"></span>
                </div>
                <TaskModalUpdate 
                    :selectedItem="selectedTask"
                    ref="drawerTaskUpdateChild"
                    drawer="drawerTaskUpdate"
                />
            </el-dialog>

            <el-drawer
                :visible.sync="dealUpdateDrawer"
                :fullscreen="true"
                ref="drawerDealUpdate"
                @opened="drawerOpened('drawerDealUpdateChild')"
                @closed="drawerClosed('drawerDealUpdateChild')"
                >
                
                <DealModalUpdate
                    ref="drawerDealUpdateChild"
                    :dealCreate="false"
                    :selectedDeal="selectedDeal"
                    drawer="drawerDealUpdate"
                />
            </el-drawer>
        </div>
        <el-dialog
            title="Напоминания"
            :visible.sync="createNotificationDialog"
            :append-to-body="true"
            width="35%"
            center
            ref="dialogCreateNotification"
            @opened="drawerOpened('dialogCreateNotificationChild')"
            @closed="drawerClosed('dialogCreateNotificationChild')"
        >
            <CreateNotification
                @closeCalendarNotification="closeCalendarNotification"
                ref="dialogCreateNotificationChild"
                drawer="dialogCreateNotification"
                :clickedDate="clickedDate"
            >
            </CreateNotification>
        </el-dialog>

        <el-dialog
            title="Напоминания"
            :visible.sync="updateNotificationDialog"
            :append-to-body="true"
            width="35%"
            center
            ref="dialogUpdateNotification"
            @opened="drawerOpened('dialogUpdateNotificationChild')"
            @closed="drawerClosed('dialogUpdateNotificationChild')"
        >
            <UpdateNotification
                @closeCalendarNotification="closeCalendarNotification"
                ref="dialogUpdateNotificationChild"
                drawer="dialogUpdateNotification"
                :selectedItem="selectedNotification"
            >
            </UpdateNotification>
        </el-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import FullCalendar from '@fullcalendar/vue';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction'
import TaskModalUpdate from "@/views/tasks/components/add-modal-update";
import DealModalUpdate from "@/views/deals/components/add-modal";
import CreateNotification from "./components/createNotification";
import UpdateNotification from "./components/updateNotification";
import selectFilial from "@/components/inventory/select-filial";

export default {
    name: "calendar",
    components: {
        FullCalendar,
        TaskModalUpdate,
        DealModalUpdate,
        CreateNotification,
        UpdateNotification,
        selectFilial,
    },

    data() {
        return {
            filterForm: {
                filial_id: null
            },
            clickedDate: '',
            currentEventType: '',
            taskUpdateDrawer: false,
            dealUpdateDrawer: false,
            createNotificationDialog: false,
            updateNotificationDialog: false,
            selectedDeal: {},
            selectedTask: {},
            selectedNotification: {},
            loadingData: false,

            calendarOptions: {
                locale: 'ru',
                firstDay: 1,
                plugins: [ dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin ],
                initialView: 'dayGridMonth',
                customButtons: {
                    getDealsButton: {
                        text: this.$t("message.deals"),
                        click: this.getAllDeals
                    },
                    getTasksButton: {
                        text: this.$t("message.tasks"),
                        click: this.getAllTasks
                    },
                    getNotificationsButton: {
                        text: this.$t("message.calendarNotification"),
                        click: this.getAllNotifications
                    },
                },
                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title getDealsButton getTasksButton getNotificationsButton',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                },
                buttonText: {
                    today: this.$t("message.today"),
                    month: this.$t("message.monthly"),
                    week: this.$t("message.weekly"),
                    day: this.$t("message.day"),
                },
                // editable: true,
                // selectable: true,
                eventDrop: this.handleEditEvent,
                dateClick: this.handleDateClick,
                select: this.handleSelect,
                eventClick: this.handleEventClick,
                // eventContent: this.handleEventContent, // for adding html element
                events: [
                    // {id: 1, title: 'Test', color: '#90EE90', start: '2022-04-15', end: '2022-04-25'},
                    // {id: 2, title: 'Test2', color: '#000000', start: '2022-04-15', end: '2022-04-25'}
                ],
                eventTimeFormat: {
                    hour: '2-digit',
                    minute: '2-digit',
                    meridiem: false
                },
                displayEventEnd: true,
                allDayText: 'Весь день',
                eventShortHeight: 10
                
            }
        };
    },
    watch: {
        filterForm: {
            handler: function(newVal, oldVal) {
                if(this.currentEventType === 'deal'){
                    this.getAllDeals();
                }
                if(this.currentEventType === 'task'){
                    this.getAllTasks();
                }
            },
            deep: true,
            immediate: true,
        },
    },

    computed: {
        ...mapGetters({
            taskList: "tasks/tasksForCalendar",
            dealList: "deals/dealsForCalendar",
            notificationList: "calendarNotifications/list",
            mode: "MODE"
        }),
        mode () {
            return this.$store.getters.MODE
        }
    },
    methods: {
        ...mapActions({
            getTaskList: "tasks/getTasksForCalendar",
            getDealList: "deals/getDealsForCalendar",
            getNotificationList: "calendarNotifications/index",
        }),
        closeCalendarNotification(val){
            this.updateNotificationDialog = false;
            this.createNotificationDialog = false;
        },
        getAllNotifications() {
            this.loadingData = true;
            this.getNotificationList()  
                .then(() => {
                    this.loadingData = false;
                    this.currentEventType = 'notification';
                    this.calendarOptions.events = JSON.parse(JSON.stringify(this.notificationList))
                }) 
                .catch(() => {
                    this.loadingData = false;
                })   
        },
        getAllDeals() {
            this.loadingData = true;
            this.getDealList(this.filterForm)  
                .then(() => {
                    this.loadingData = false;
                    this.currentEventType = 'deal';
                    this.calendarOptions.events = JSON.parse(JSON.stringify(this.dealList))
                }) 
                .catch(() => {
                    this.loadingData = false;
                })       
        },
        getAllTasks() {
            this.loadingData = true;
            this.getTaskList(this.filterForm)  
                .then(() => {
                    this.loadingData = false;
                    this.currentEventType = 'task';
                    this.calendarOptions.events = JSON.parse(JSON.stringify(this.taskList))
                })
                .catch(() => {
                    this.loadingData = false;
                })
        },
        handleDateClick (arg) {
            // arg.dateStr
            this.createNotificationDialog = true;
            this.clickedDate = arg.dateStr;
        },

        handleSelect (arg) {
            // arg.startStr, arg.endStr

        },
        handleEventContent(arg) {
            // add html in element 
        },

        handleEventClick (arg) {
            if(this.currentEventType === 'deal'){
                this.dealUpdateDrawer = true;
                this.selectedDeal = JSON.parse(JSON.stringify(this.dealList)).find(item => item.id == arg.el.fcSeg.eventRange.def.publicId);
            }
            if(this.currentEventType === 'task'){
                this.taskUpdateDrawer = true;
                this.selectedTask = JSON.parse(JSON.stringify(this.taskList)).find(item => item.id == arg.el.fcSeg.eventRange.def.publicId);
            }
            if(this.currentEventType === 'notification'){
                this.updateNotificationDialog = true;
                this.selectedNotification = JSON.parse(JSON.stringify(this.notificationList)).find(item => item.id == arg.el.fcSeg.eventRange.def.publicId);
            }
        },
        handleEditEvent (arg) {
            // has old date and has new date
        },

        listChanged() {
            this.reloadList = true;
        },
        closeDrawer(drawer) {       
            this.taskUpdateDrawer = false;
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                
                this.$refs[drawer].closeDrawer();
            }
        },
        closeAddModel() {
            this.dealUpdateDrawer = false;
            if(this.reloadList === true){
                this.updateDealsList().then(res => this.reloadList === false);
            }
        },
        drawerClosed(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].closed()
            }
            if (this.reloadList === true) {
                if(ref === 'drawerCreateContactChild') {
                    this.updateContactList().then(res => this.reloadList === false);
                    
                }if(ref === 'drawerCreateTaskChild'){
                    this.updateTaskList().then(res => this.reloadList === false);
                }
            }
            if (_.isFunction(this.empty)) {
                this.empty()
            }
        },
        drawerOpened(ref) {        
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },
    
    },
};
</script>
<style>
    .calendar_main {
        margin: 0 30px;
    }
    
    .calendarday div div  table {
        border: 1px solid #000 !important;
    }
    .calendarday div div  table tbody tr td {
        border: 1px solid #000 !important;
        color: #000 !important;
    }
    .calendarnight div div  table {
        border: 1px solid #fff !important;
    }
    .calendarnight div div  thead tr th {
        color: #fff !important;
        border: 1px solid #fff !important;
    }
    .calendarnight div div  table tbody tr td {
        border: 1px solid #fff !important;
        color: #fff !important;
    }
</style>
