<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item :label="$t('message.name')" prop="title">
        <el-input type="textarea" autosize v-model="form.title" :placeholder="$t('message.name')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('message.comment')">
        <el-input type="textarea" autosize v-model="form.comment" :placeholder="$t('message.comment')"></el-input>
      </el-form-item>
      <el-form-item :label="'Напомнить за ' + form.alert_before_minutes + ' минут раньше'">
        <el-select size="medium" v-model="form.alert_before_minutes" placeholder="минут" class="w-100">
          <el-option
            v-for="item in minutes"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('message.end_date')" prop="start">
        <el-date-picker
          type="datetime"
          :placeholder="$t('message.end_date')"
          v-model="form.start"
          style="width: 100%"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
        ></el-date-picker>
      </el-form-item>
    </el-form>
    <div
      class="button-style-my mb-4"
      @click="participantsDrawer = true"
    >
        <i class="el-icon-user"></i>
        <p>{{ $t("message.participants") }}</p>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeModal()">Отмена</el-button>
      <el-button @click="submit(true)" type="primary" v-loading="loadingButton">{{ $t("message.confirm") }} </el-button>
    </span>
    <div>
      <el-dialog
        :title="$t('message.participants')"
        :visible.sync="participantsDrawer"
        width="30%"
        :append-to-body="true"
        class="add-participants"
        ref="drawerParcipants"
        @opened="drawerOpened('drawerParcipantsChild')"
        @closed="drawerClosed('drawerParcipantsChild')"
      >
        <participants
          ref="drawerParcipantsChild"
          drawer="drawerParcipants"
          :participants="form.participants"
          @getCheckedUsers="updateParticipants"
          > </participants>
      </el-dialog>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import participants from "./participants";

export default {
  components: {
    participants,
  },
  mixins: [form, drawer, show],
  props: {
    clickedDate: {
      default: '',
    },
  },
  data() {
    return {
      participantsDrawer: false,
      minutes: [
        {
          value: 5,
          label: '5'
        }, 
        {
          value: 10,
          label: '10'
        },
        {
          value: 15,
          label: '15'
        },
        {
          value: 20,
          label: '20'
        },
        {
          value: 30,
          label: '30'
        },
        {
          value: 45,
          label: '45'
        },
      ],
    };
  },
  created() {},
  watch: {
  },
  computed: {
      ...mapGetters({
          rules: "calendarNotifications/rules",
          model: "calendarNotifications/model",
          columns: "calendarNotifications/columns",
          authUser: "auth/user",
      }),
  },
  methods: {
      ...mapActions({
          save: "calendarNotifications/update",
          show: "calendarNotifications/show",
          empty: "calendarNotifications/empty",
          updateDailyNotifications: "calendarNotifications/dailyNotifications",
      }),
      closeModal() {
        this.$emit('closeCalendarNotification', true)
      },
        updateParticipants(users){
        this.form.participants = JSON.parse(JSON.stringify(users)).map(item => item.id); 
      },
      closeDrawer(drawer) {       
          this.participantsDrawer = false;
      },
      drawerClosed(ref) {
          if (this.$refs[ref]) {
              this.$refs[ref].closed()
          }
      },
      drawerOpened(ref) {        
          if (this.$refs[ref]) {
              if (_.isFunction(this.$refs[ref].opened)) {
                  this.$refs[ref].opened()
              }
          }
      },
      submit(close = true) {
          this.form.user_id = this.authUser ? this.authUser.id : null
          this.$refs["form"].validate((valid) => {
              if (valid) {
                  this.loadingButton = true;
                  this.save(this.form)
                      .then((res) => {
                          this.loadingButton = false;
                          this.$alert(res);
                          this.$emit('closeCalendarNotification', true)
                          // this.$emit('listChanged');
                          this.parent().getAllNotifications();
                          // this.updateDailyNotifications();
                          this.empty();
                          if (close) this.close();
                      })
                      .catch((err) => {
                          this.loadingButton = false;
                          this.$alert(err);
                      });
              }
          });
      },
      afterLeave() {
          this.empty();
      }
  },
};
</script>


<style>
</style>