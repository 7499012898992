<template>
  <div class="modal-add-task-participants" v-loading="loadingData">
    <div class="add-task-participants-body">
      <div class="title-task-participants">{{ $t("message.participants") }}</div>
        <div>
            <el-input
                class="w-100 mb-2"
                size="medium"
                placeholder="Поиск"
                prefix-icon="el-icon-search"
                @input="findUser"
                v-model="searchText">
            </el-input>
        </div>
      <div class="itme-chek-input" v-for="user in userList" :key="user.id" @click="checkUser(user)" :for="user.id">
        <input :id="user.id" :name="user.id" type="checkbox" v-model="user.checked">
        <label :for="user.id">{{ user.name }}</label>
      </div>
    </div>

    <div class="modal-footer-buts">
      <div class="d-flex justify-center">
        <el-button type="primary" size="medium" @click="submit(true)"> {{ $t("message.save") }} </el-button>
        <el-button type="danger" plain size="medium"  @click="close()">{{ $t("message.cancel") }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";

export default {
  mixins: [form, drawer],
  
  props: {
      participants:{
        default: Array
      },
  },
  data() {
    return {
      searchText: '',
      userList: [],
      checkedUsers: [],
      loadingData: false,
    };
  },
  computed: {
      ...mapGetters({
          users: "users/inventory",
          authUser: "auth/user",
          getUserSearch: "users/search",
      }),
  },
  methods: {
      ...mapActions({
          updateInventory: "users/inventory",
          searchUser: "users/search",
      }),
      afterOpen(){
        this.loadingData = true;
        this.updateInventory()
          .then(res => {
            this.userList = []
            this.userList = JSON.parse(JSON.stringify(this.users)).filter(item => item.id !== this.authUser.id);
            this.userList.forEach(item => {
              if(this.participants.includes(item.id)){
                item.checked = true;
                setTimeout(() => {
                  item.checked ? this.checkedUsers.push(item) : '';
                }, 100);
              }else{
                item.checked = false;
              }
            })
            this.loadingData = false;
          });  
      },
      debouncedSearchUser: _.debounce((search, self) => {
        if (_.isFunction(self.searchUser) && search.length > 0) {
          self.searchUser(search).then(res => {
              let list = JSON.parse(JSON.stringify(self.getUserSearch)).filter(item => item.id !== self.authUser.id);
                self.userList = JSON.parse(JSON.stringify(self.checkedUsers));
                list.forEach(element => {
                  if(!self.checkedUsers.find(item => item.id === element.id)){
                    self.userList.push(element);
                  }
                });
          })
        }else{
          self.userList = JSON.parse(JSON.stringify(self.users)).filter(item => item.id !== self.authUser.id);
          self.userList.forEach(element => {
            if(self.checkedUsers.map(item => item.id).includes(element.id)){
              element.checked = true;
            }
          });
        }
      }, 1000),
      findUser(val){
          this.debouncedSearchUser(val, this)
      },
      checkUser(user){
        setTimeout(() => {
          if(user.checked && !this.checkedUsers.find(item => item.id === user.id)){
            this.checkedUsers.push(user);
          }else if(!user.checked && this.checkedUsers.find(item => item.id === user.id)){
            this.checkedUsers = this.checkedUsers.filter(item => item.id !== user.id);
          }
        }, 100);
      },

      submit(close){
        let users = this.checkedUsers.filter(per => per.checked);
        this.$emit("getCheckedUsers", users);
        if (close) this.close();
      },
      afterLeave(){
          this.userList = [],
          this.checkedUsers = []
      }
  },

};
</script>

<style>

.itme-chek-input {
    padding: 7px 0px;
    border-top: 1px solid #0000000d;
    width: 100%;
    font-size: 16px;
}
.itme-chek-input input {
    margin: 0px 10px;
}
.itme-chek-input label{
    width: 30px;
    margin: 0px 10px;
}

</style>
